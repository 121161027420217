import { useState, useEffect } from 'react'
import {catchAsyncStacktrace} from 'auto-trace';
import {getLoggedInUserAsObservable, getTenantAsObservable} from './user-tenant-data.js';

export default function UseWithUserAndTenant() {
  const [ user, setUser ] = useState()
  const [ tenant, setTenant] = useState()

  useEffect(
    () => {
      const tenant$ = getTenantAsObservable().subscribe(
        tenant => setTenant(tenant),
        catchAsyncStacktrace()
      )
      const user$ = getLoggedInUserAsObservable().subscribe(
        user => setUser(user),
        catchAsyncStacktrace()
      )
      return () => {
        tenant$.unsubscribe()
        user$.unsubscribe()
      }
    }, []
  )
  return [user, tenant]
}
