import React from 'react';
import ReactDOM from 'react-dom';
import PracticeManagementFreemiumWelcomeBackModal from './practice-management-freemium-welcome-back-modal.component';
import { filter, concatMap, first, delay } from 'rxjs/operators';
import { getLoggedInUserAsObservable } from "src/user-tenant-data.js";
import Auth from 'src/cp-client-auth.js';

export async function checkPracticeManagementFreemiumWelcomeBackModal() {
  const { fetchAsObservable } = await SystemJS.import("fetcher!sofe");

  return new Promise((resolve, reject) => {
    getLoggedInUserAsObservable().pipe(
      filter(user => !!user),
      first(),
      delay(2500),
      concatMap(user => fetchAsObservable(`api/contacts/reached_limit`)),
    ).subscribe(
      data => {
        let container;

        if (
          Auth.getCookie().enforce_contact_limit
          && !sessionStorage.getItem('hasClosedPracticeManagementFreemiumWelcomeBackModal')
        ) {
          container = document.createElement('div');
          container.id = "practice-management-freemium-welcome-back-container"
          document.body.appendChild(container);

          ReactDOM.render(
            <PracticeManagementFreemiumWelcomeBackModal
              contactsCount={data.used}
              close={close}
            />,
            container
          )
        }
  
        function close() {
          if (container) {
            ReactDOM.unmountComponentAtNode(container);
            container.remove();
          }
        }
      },
      error => reject(error),
      () => resolve()
    )
  })
}