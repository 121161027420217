import React from 'react';
import Cancelable from 'react-disposable-decorator';
import {catchAsyncStacktrace} from 'auto-trace';
import {getLoggedInUserAsObservable, getTenantAsObservable} from './user-tenant-data.js';
import {noop} from 'lodash';

@Cancelable
export default class WithUserAndTenant extends React.Component {
  state = {
    loggedInUser: null,
    tenant: null,
  }
  static defaultProps = {
    onUserChange: noop,
    onTenantChange: noop,
    onAnyChange: noop,
    waitForData: false,
  }
  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getLoggedInUserAsObservable()
      .subscribe(
        loggedInUser => this.setState({loggedInUser}, this.userChanged),
        catchAsyncStacktrace()
      ),

      getTenantAsObservable()
      .subscribe(
        tenant => this.setState({tenant}, this.tenantChanged),
        catchAsyncStacktrace()
      )
    );
  }
  render() {
    const hasData = this.state.loggedInUser && this.state.tenant;

    if (this.props.waitForData && !hasData) {
      return null;
    } else {
      return this.props.children({loggedInUser: this.state.loggedInUser, tenant: this.state.tenant});
    }
  }
  userChanged = () => {
    this.props.onUserChange(this.state);
    this.props.onAnyChange(this.state);
  }
  tenantChanged = () => {
    this.props.onTenantChange(this.state);
    this.props.onAnyChange(this.state);
  }
}
