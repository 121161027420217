export function shouldShowAgreement(type, user, legalAgreements) {
  if (!user.legal_agreements) return true; // the user has yet to accept the new flow

  const lastAgreement = user.legal_agreements.reduce(
    toLatestAgreement(type),
    null
  );
  const latestAvailableAgreement = legalAgreements.reduce(
    toLatestAgreement(type),
    null
  );

  if (
    !lastAgreement ||
    latestAvailableAgreement.version > lastAgreement.version
  )
    return true;

  return false;
}

export function toLatestAgreement(type) {
  return (found, agreement) => {
    if (agreement.type === type) {
      if (!found) return agreement;
      if (agreement.version > found.version) return agreement;
    }

    return found;
  };
}
