import canopyUrls, { showPageNotFound } from 'canopy-urls!sofe';
import { captureMessage } from 'sentry-error-logging!sofe';
import {hasAccess} from './permission.helpers.js';
import {hasBetaObs} from './betas';
import {checkLegalAgreements} from './legal-agreements/legal-agreements-setup.js';
import {checkPracticeManagementFreemiumWelcomeBackModal} from './practice-management-freemium/practice-management-freemium-setup.js';
import {
  refetchLoggedInUser,
  updateLoggedInUserObservable,
  refetchTenant,
  updateTenantObservable,
  checkLoginStatus,
  getLoggedInUserAsObservable,
  getTenantAsObservable,
} from './user-tenant-data.js';
import WithUserAndTenantComponent from './with-user-and-tenant.component.js';
import {noop, isNumber} from 'lodash'
import {getBestAuthInfoCookie} from './cookie-helpers.js'
import useWithUserAndTenantHook from './use-with-user-and-tenant.js'
import UserTenantPropsComponent from './user-tenant-props.decorator.js';

export {hasAccess} from './permission.helpers.js';
export const UserTenantProps = UserTenantPropsComponent;
export const WithUserAndTenant = WithUserAndTenantComponent;
export const useWithUserAndTenant = useWithUserAndTenantHook;
export {useHasAccess} from './use-has-access'
export {useBetas} from './use-betas'
export {
  hasLicense,
  userHasOnlyLicense,
  isFreeLicense,
  hasPurchasedLicense,
  isWithinLicenseLimit,
  getAvailableLicenseCount,
  isFreemiumPracticeManagementLicense, // DEPRECATED. Use 'isContactLimitPracticeManagementLicense' instead
  isContactLimitPracticeManagementLicense,
} from './licenses.helper';
// DEPRECATED ================================================
// Use exports from 'contact-limit-model.hooks' instead
export {useIsFreemiumPracticeManagement} from './use-is-freemium-practice-management.hook';
// ===========================================================
export {
  isContactLimitModel,
  isFreeContactLimitModelTier,
  isPaidContactLimitModelTier,
} from './contact-limit-model.helper';
export {useIsContactLimitModel, useContactLimitModelTier} from './contact-limit-model.hooks';

let timeout = null;
let trackingSession = false;

function serialize(data) {
  return Object.keys(data).map(function (keyName) {
    return encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName]);
  });
}

function getTimeoutUrl() {
  let loginUrl = `${canopyUrls.getAuthUrl()}`
  if (window.appIsMobile) {
    loginUrl = `${loginUrl}/m/login`
  } else {
    loginUrl = `${loginUrl}/#/login`
  }
  const logout = loginUrl.replace('login', 'logout')
  const href = window.location.href
  if (!(href.includes(loginUrl) || href.includes(logout))) {
    return `${loginUrl}/timed-out?redirect_url=${encodeURIComponent(href)}`
  }
}

function timeoutRedirect() {
  let url = getTimeoutUrl()

  // We want to force a page reload on timeout because not all child applications unmount everything correctly.
  // For example, modals created by one child app but that are rendered by a different sofe service.
  // The only way I know of to force a reload when navigating to the same domain is to change the
  // protocol in the url. Since the load balancer auto-redirects from http -> https, this works.
  if (url && url.startsWith('https')) {
    url = 'http' + url.slice('https'.length);
  }

  if (!!url) {
    window.location.assign(url)
  }
}

function startTimer(seconds) {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(async () => {
    let time
    try {
      time = await auth.getTimeRemaining()
    } catch {
      return timeoutRedirect()
    }
    if (!time || !isNumber(time.seconds)) {
      captureMessage('cp-client-auth startTimer is missing time.seconds!', { time })
      return
    }

    if (time.seconds < 1) {
      timeoutRedirect();
    } else {
      startTimer(time.seconds * 1000);
    }
  }, seconds)
}

let currentRefreshPromise = null;

let auth = {
  hasAccess,
  hasBetaObs,
  refetchLoggedInUser,
  updateLoggedInUserObservable,
  refetchTenant,
  updateTenantObservable,
  checkLoginStatus,
  getTenantAsObservable,
  getLoggedInUserAsObservable,

  /**
   * Get the csrf token from the session cookie
   */
  getCSRFToken() {
    return getBestAuthInfoCookie().csrf_token;
  },

  /**
   * Refresh the current authentication token. This needs to take place
   * after the token has expired and before the refresh token expires.
   *
   * Only one http refresh request is made at a time. If refreshAuthToken is called
   * multiple times while the token is in the process of being refreshed, the promises
   * are queued up and resolved all together once the token refreshes.
   *
   * If the token fails to refresh, the user is forwarded to login.
   *
   * If cp-auth is also tracking the session, on a successfull refreshToken we reset
   * the timeout tracker. See auth.trackSession().
   *
   * @param {Object} options an options object which contains the clientSecret
   *                 The client secret is necessary from the client to make the request.
   * @return {Promise} A promise which will resolve when the token successfully refreshes
   */
  refreshAuthToken(opts = {}) {
    if (!opts.clientSecret) {
      opts.clientSecret = 'TaxUI:f7fsf29adsy9fg'
    }

    if (currentRefreshPromise) {
      return currentRefreshPromise;
    } else {
      return currentRefreshPromise = Promise.resolve().then(() => {
        const url = `${canopyUrls.getAPIUrl()}/token`
        const rawJsonBody = {grant_type: 'refresh_token'}
        const body = JSON.stringify(rawJsonBody);
        const csrfToken = this.getCSRFToken()
        const headers = {
          "Authorization": 'Basic ' + btoa(opts.clientSecret),
          "Content-Type": "application/json"
        }
        if (csrfToken) {
          headers['X-CSRF-TOKEN'] = csrfToken
        }
        return fetch(url, {
          method: 'post',
          headers: new Headers(headers),
          credentials: 'include',
          body
        }).then((response) => {
          if (!response.ok) {
            throw new Error('Session has expired');
          }

          if (trackingSession) {
            startTimer();
          }
          return response.json();
        })
          .then((...resp) => {
            currentRefreshPromise = null;
          })
          .catch(err => {
            currentRefreshPromise = null;

            if (!opts.preventLoginRedirect) {
              /* Redirecting to the login page:
               *
               * If we resolve the promise, that is misleading because the identity token
               * has not been refreshed. If we reject the promise, a lot of code will log it as an
               * error to sentry because they catchAsyncStacktrace() fetcher calls (and fetcher refreshes the auth token).
               * So, instead, what we do is return a promise that never resolves or rejects. And hope that it doesn't cause a
               * memory leak (not sure if it does or not). That way the code that is waiting for this never gets called.
               */
              timeoutRedirect(err);
              return new Promise(noop);
            } else {
              throw err
            }
          })
      })
    }
  },

  /**
   * Return user information from the session cookie
   */
  getUserInfo() {
    const cookie = getBestAuthInfoCookie();
    return {
      userId: cookie.userId,
      userName: cookie.userName,
      email: cookie.email,
    }
  },

  /**
   * Return the whole session cookie
   */
  getCookie() {
    return getBestAuthInfoCookie();
  },

  /**
   * Request from the server how much time is remaining on the refreshToken.
   *
   * @return {Object} An object which contains a "seconds" attribute
   */
  getTimeRemaining() {
    return fetch('/wg/token', {
      method: 'get',
      headers: {
        Accept: 'application/json'
      },
      credentials: 'include'
    }).then((response) => {
      return response.json();
    });
  },

  /**
   * Allow cp-auth to track the user session and automatically redirect them to
   * the login timeout page when their refresh token expires.
   */
  trackSession() {
    trackingSession = true;
    startTimer();
  },

  /**
   * Check if user is on the freemium transcripts-only license, and watch their
   * hash changes to enforce the routes they're able to access
  */
  trackFreemiumUser(user) {
    const isTranscriptsOnlyUser = user.licenses.length === 1 && user.licenses[0] === 'transcripts';
    if (isTranscriptsOnlyUser) {
      const transcriptsOnlyAllowedPrefixes = [
        '#/transcripts',
        '#/global-settings/user',
        '#/global-settings/team',
        '#/global-settings/company',
        '#/global-settings/licenses',
        '#/global-settings/expired',
        '#/admin/global-settings/transcript-preferences',
      ];
      function checkRoute() {
        setTimeout(() => {
          if (location.hash.length && !transcriptsOnlyAllowedPrefixes.some(prefix => location.hash.startsWith(prefix)))
            showPageNotFound();
        });
      }
      checkRoute();
      window.addEventListener('hashchange', checkRoute);
    }
  },

};

checkLegalAgreements().catch(error =>
  setTimeout(() => {
    throw error;
  })
);

window.cpAuth = auth;
export default auth;

checkPracticeManagementFreemiumWelcomeBackModal().catch(error => {
  error.showToast = false;
  SystemJS.import('sentry-error-logging!sofe').then(m => {
    m.captureException(error);
  })
})
