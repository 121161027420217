import { createDisposableModal } from "react-disposable-modal";
import LegalAgreementsModal from "./legal-agreements-modal.component";
import {
  getLoggedInUserAsObservable,
  getTenantAsObservable
} from "../user-tenant-data.js";
import { combineLatest } from 'rxjs'
import { delay, map, mergeMap, switchMap, pluck, filter } from "rxjs/operators";
import { shouldShowAgreement } from "./legal-agreements.helper";

export async function checkLegalAgreements() {
  if(window.preAuthUrlActive) return

  const { fetchAsObservable } = await SystemJS.import("fetcher!sofe");

  const combinedUserTenant$ = combineLatest(
    getLoggedInUserAsObservable(),
    getTenantAsObservable(),
  )

  combinedUserTenant$.pipe(
    filter(v => {
      const [user, tenant] = v
      if (user && tenant) {
        return user.tenantId === tenant.id && legalAgreementsAreStale(user, window.latestAgreements)
      } else {
        return false
      }
    }),
    delay(2500),
    mergeMap(([user, tenant]) => fetchAsObservable('/wg/legal-agreements').pipe(
      pluck('legal-agreements'),
      map((legalAgreements) => [user, tenant, legalAgreements]),
    )),
    switchMap(([user, tenant, legalAgreements]) => renderLegalAgreements(tenant, user, legalAgreements))
  ).subscribe(() => {
    /* completed */
  });
}

function legalAgreementsAreStale(user, latestAgreements) {
  const legalAgreements = [
    { type: "Privacy Policy", version: latestAgreements.privacy },
    { type: "Terms of Service", version: latestAgreements.tos }
  ];

  return (
    shouldShowAgreement("Privacy Policy", user, legalAgreements) ||
    shouldShowAgreement("Terms of Service", user, legalAgreements)
  );
}

function renderLegalAgreements(tenant, user, legalAgreements) {
  return createDisposableModal(LegalAgreementsModal, {
    tenant,
    user,
    legalAgreements
  });
}
