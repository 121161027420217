import useWithUserAndTenant from 'src/use-with-user-and-tenant';
import {isContactLimitModel, isPaidContactLimitModelTier, isFreeContactLimitModelTier} from './contact-limit-model.helper';

export function useIsContactLimitModel() {
  const [user, tenant] = useWithUserAndTenant();
  return tenant ? isContactLimitModel(tenant.contact_limit) : null; // if tenant is null, return null
}

export function useContactLimitModelTier() {
  const [user, tenant] = useWithUserAndTenant();

  if (tenant) {
    if (isPaidContactLimitModelTier(tenant.contact_limit)) {
      return 'PAID';
    } else if (isFreeContactLimitModelTier(tenant.contact_limit)) {
      return 'FREE';
    } else {
      return 'NONE';
    }
  } else {
    return null; // if tenant is null, return null
  }
}