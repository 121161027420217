import { ReplaySubject } from 'rxjs'
import { catchSyncStacktrace } from 'auto-trace';
import auth from './cp-client-auth.js';

let currentUser, currentTenant, userFetchedOnce = false, tenantFetchOnce = false;
const userSubjectRxjs = new ReplaySubject(1)
const tenantSubjectRxjs = new ReplaySubject(1)

if (window.loggedInUser && isNotOnLogoutPage()) {
  setUser(window.loggedInUser);
}

if (window.tenant && isNotOnLogoutPage()) {
  setTenant(window.tenant);
}

function isNotOnLogoutPage () {
  return window.location.hash.indexOf('#/logout') === -1 && !window.location.href.includes('/m/logout')
}

export function getLoggedInUserAsObservable() {
  fetchUserIfNotFetched()
  return userSubjectRxjs.asObservable();
}

function fetchUserIfNotFetched() {
  if (!userFetchedOnce) {
    refetchLoggedInUser(true)
      .catch(err => {
        if (err && err.status === 401) {
          // ignore
        } else {
          catchSyncStacktrace(err);
        }
      })
  }
  userFetchedOnce = true;
}

export function refetchLoggedInUser(passThrough401) {
  return SystemJS.import('fetcher!sofe')
    .then(fetcherModule => Promise.all([
      fetcherModule.fetchAsObservable('/users/0', {passThrough401}).toPromise(),
      fetcherModule.fetchAsObservable('/betas', {passThrough401}).toPromise(),
    ]))
    .then(([{ users }, { betas }]) => ({
      ...users,
      betas,
    }))
    .then(setUser)
    .catch(err => {
      if (err && err.status === 401) {
        // ignore
      } else {
        catchSyncStacktrace(err);
      }
    })
}

export function updateLoggedInUserObservable(newUser) {
  setUser(newUser)
}

function setUser(user) {
  const betas = user?.betas || currentUser?.betas || [];
  currentUser = { ...user, betas };
  userSubjectRxjs.next(currentUser)
  return currentUser;
}

export function getTenantAsObservable() {
  fetchTenantIfNotFetched()
  return tenantSubjectRxjs.asObservable();
}

function fetchTenantIfNotFetched() {
  if (!tenantFetchOnce) {
    refetchTenant(true)
      .catch(err => {
        if (err && err.status === 401) {
          // ignore
        } else {
          catchSyncStacktrace(err);
        }
      })
  }
  tenantFetchOnce = true;
}

export function refetchTenant(passThrough401) {
  return SystemJS.import('fetcher!sofe')
    .then(fetcherModule => fetcherModule.fetchAsObservable('/tenants/0', {passThrough401}).toPromise())
    .then(data => data.tenants)
    .then(setTenant)
}

export function updateTenantObservable(newTenant) {
  setTenant(newTenant);
}

function setTenant(tenant) {
  currentTenant = tenant;
  tenantSubjectRxjs.next(currentTenant);
  return currentTenant;
}

export function checkLoginStatus() {

  if (window.preAuthUrlActive) {
    const params = new URL(window.location).searchParams;
    const preAuthToken = params.get('token')
    if (preAuthToken) {
      return Promise.all([refetchLoggedInUser(true), refetchTenant(true)])
        .then(([user, tenant]) => {
          return {isLoggedIn: true}
        })
        .catch(err => ({isLoggedIn: false}))
    } else {
      return Promise.resolve({isLoggedIn: false})
    }
  }

  return Promise.all([SystemJS.import('fetcher!sofe'), SystemJS.import('canopy-urls!sofe')])
    .then(([fetcherModule, canopyUrlsModule]) => {
      return fetcherModule.default(`${canopyUrlsModule.default.getAPIUrl()}/token`, {passThrough401: true})
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        return Promise.reject(Error(`Could not check if user is logged in -- GET /token responded with HTTP status '${response.status}'`))
      }
    })
    .then(data => {
      if (data.identitySeconds < 0 && data.refreshSeconds < 0) {
        // Neither identity nor refresh token exists/is valid.
        return {isLoggedIn: false};
      } else if (data.identitySeconds < 0) {
        // Our identity token got overridden (maybe logging into different env on same domain), but we can get a new one with refresh token.
        // TODO: check if this is contributing to the token thrashing
        return auth.refreshAuthToken({preventLoginRedirect: true})
          .then(() => {
            // We should update the user and tenant observables (needed for when the app is initializing and couldn't get the user and tenant because identity token expired)
            setTimeout(() => {
              refetchLoggedInUser();
              refetchTenant();
            });

            return {isLoggedIn: true}
          })
          .catch(err => ({isLoggedIn: false}))
      } else if (data.refreshSeconds < 0) {
        // Somehow they have a valid identity token but have lost their refresh token. They can make api calls until their identity token runs out.
        return {isLoggedIn: true};
      } else {
        // Both identity seconds and refresh seconds are greater than zero.
        return {isLoggedIn: true};
      }
    })
}
