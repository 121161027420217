import React from 'react';
import {getLoggedInUserAsObservable, getTenantAsObservable} from './user-tenant-data.js';
import {catchAsyncStacktrace} from 'auto-trace';
import Cancelable from 'react-disposable-decorator'
import { hasAccess } from './permission.helpers.js';

const defaultOpts = {
  // Don't render the component until the user and tenant and rendered. On by default
  waitForData: true,
  permissions: {},
};

export default function(opts = defaultOpts) {
  return DecoratedComponent => {
    class UserTenantProps extends React.Component {
      state = {
        loggedInUser: null,
        tenant: null,
        permissions: {},
      }
      componentDidMount() {
        this.props.cancelWhenUnmounted(
          getLoggedInUserAsObservable()
          .subscribe(
            loggedInUser => this.setState({
              loggedInUser,
              permissions: Object.keys(opts.permissions || {}).reduce((acc, key) => {
                acc[key] = hasAccess(loggedInUser)(opts.permissions[key])
                return acc
              }, {})
            }),
            catchAsyncStacktrace()
          ),

          getTenantAsObservable()
          .subscribe(
            tenant => this.setState({tenant}),
            catchAsyncStacktrace()
          )
        );
      }
      render() {
        const hasData = this.state.loggedInUser && this.state.tenant;
        const {loggedInUser, tenant, permissions} = this.state
        if (opts.waitForData && !hasData) {
          return null;
        } else {
          return <DecoratedComponent {...this.props} {...{loggedInUser, tenant, permissions}}  />
        }
      }
    }

    return Cancelable(UserTenantProps)
  }
}
