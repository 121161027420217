import { useState, useEffect } from "react";
import UseWithUserAndTenant from "./use-with-user-and-tenant";

export function useBetas(betas=[], requireAll) {
  if (!betas || !betas.length) {
    console.warn("No betas were passed to `useBetas`")
  }
  const [user, tenant] = UseWithUserAndTenant()
  const [access, setAccess] = useState()

  const checkBeta = (beta) => {
    return user?.betas?.some(tenantBeta => beta === tenantBeta)
  }

  useEffect(() => {
    if (user) {
      if (typeof betas === 'string') {
        return setAccess(checkBeta(betas))
      }
      const hasAccess = requireAll ? betas.every(checkBeta) : betas.some(checkBeta)
      setAccess(hasAccess)
    }
  }, [`${user?.betas}`, `${betas}`, requireAll]);
  return access;
}
