import React, { useState } from "react";
import styles from "./legal-agreements-modal.styles.css";
import { CprButton } from "canopy-styleguide!sofe";
import { useAgreeToVersions } from "./legal-agreements.hooks.js";
import { shouldShowAgreement, toLatestAgreement } from "./legal-agreements.helper";

export default function LegalAgreementsModal(props) {
  const { user, legalAgreements, tenant, onCompleted } = props;
  const [agreed, setAgreed] = useState(false);
  const [saving, setPendingPatch] = useAgreeToVersions(user, onCompleted);

  const latestTerm = legalAgreements.reduce(toLatestAgreement('Terms of Service'), null);
  const latestPolicy = legalAgreements.reduce(toLatestAgreement('Privacy Policy'), null);

  const showPolicy = shouldShowAgreement('Privacy Policy', user, legalAgreements);
  const showTerms = shouldShowAgreement('Terms of Service', user, legalAgreements);

  const isClientPortal =
    window.location.hostname.includes("clientportal.com") ||
    window.location.hostname.includes("clientportal.ninja");

  let agreementText = "";
  if (showPolicy && showTerms) {
    agreementText = "Terms of Service and Privacy Policy";
  } else if (!showPolicy && showTerms) {
    agreementText = "Terms of Service";
  } else {
    agreementText = "Privacy Policy";
  }

  if (showPolicy || showTerms) {
    return (
      <div className={`${styles.modal}`}>
        <div className={`${styles.card}`}>
          <div className={`${styles.header} cps-margin-top-8`}>
            <div className="cps-subheader-sm cps-wt-semibold">
              We have updated our {agreementText}.
            </div>
            <div>
              {
                isClientPortal && <span>clientportal.com is powered by Canopy Tax. </span>
              }
              <span>Please read below and accept to continue.</span>
            </div>
          </div>
          <div className={`${styles.body}`}>
            <div className={`${styles.agreementTerm}`}>
              {showTerms && (<div dangerouslySetInnerHTML={{__html: latestTerm.content}}/>)}
              {showPolicy && (<div dangerouslySetInnerHTML={{__html: latestPolicy.content}}/>)}
            </div>
          </div>
          <div className={styles.footer} style={{marginBottom: 0}}>
            <form onSubmit={formSubmit}>
              <label className="cps-checkbox">
                <input
                  type="checkbox"
                  checked={agreed}
                  onChange={evt => {
                    setAgreed(evt.target.checked);
                  }}
                />
                <span>I have read and accept the Canopy {agreementText}</span>
              </label>
              <div className={`cps-margin-top-16 ${styles.buttons}`}>
                <CprButton
                  type="submit"
                  actionType="primary"
                  disabled={!agreed || saving}
                >
                  Submit
                </CprButton>
                <CprButton type="button" actionType="flat">
                  <a href={isClientPortal ? "https://help.clientportal.com/" : "https://help.getcanopy.com/"} target="_blank" rel="noopener noreferrer">
                    Help Center
                  </a>
                </CprButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }

  function formSubmit(evt) {
    evt.preventDefault();

    let pendingSave = [];

    if (showPolicy) {
      pendingSave.push({
        version: latestPolicy.version,
        type: latestPolicy.type,
        timestamp: window.appLoaderInitialTime,
      });
    }
    if (showTerms) {
      pendingSave.push({
        version: latestTerm.version,
        type: latestTerm.type,
        timestamp: window.appLoaderInitialTime,
      });
    }

    setPendingPatch(pendingSave);
  }
}
