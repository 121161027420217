// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__dialog--3aYpd {\n  width: 53.0rem;\n}\n\n.src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__header--3ZI0b {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding: 1.4rem;\n}\n\n.src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__body--zpCKu {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 2.4rem;\n  font-size: 1.6rem;\n  color: var(--cps-color-cool-gray);\n  text-align: center;\n}\n\n.src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__icon--P68FR {\n  width: 9.6rem;\n  height: 9.6rem;\n  margin-bottom: 2.4rem;\n}\n\n.src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__updatedText--2gja8 {\n  margin-top: 2.4rem;\n  font-style: italic;\n}\n\n.src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__upgradeText--TB9CP {\n  margin: 2.4rem 0;\n}", ""]);
// Exports
exports.locals = {
	"dialog": "src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__dialog--3aYpd",
	"header": "src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__header--3ZI0b",
	"body": "src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__body--zpCKu",
	"icon": "src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__icon--P68FR",
	"updatedText": "src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__updatedText--2gja8",
	"upgradeText": "src-practice-management-freemium-practice-management-freemium-welcome-back-modal-styles__upgradeText--TB9CP"
};
module.exports = exports;
