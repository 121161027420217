import { isEmpty } from 'lodash'
// requireAll: if true, the function checks if the user has ALL of the given permissions. If false, checks if user has ANY of the permissions.
export function hasAccess(user, _requireAll=false) {
  return function(permissions = [], __requireAll=false) {

    if (isEmpty(user)) {
      return false;
    }

    const requireAll = _requireAll || __requireAll;
    if (!(permissions instanceof Array)) permissions = [permissions];
    permissions.forEach(p => {
      if (Object.keys(deprecatedPermissions).includes(p)) {
        const newPermission = deprecatedPermissions[p]
        console.warn(`The permission "${p}" has been deprecated. ${newPermission ? `Please use ${newPermission} instead.` : ''}`)
      }
    })
    permissions = permissions.map(toSnake);
    return requireAll
      ? permissions.every(permission => user.permissions[permission])
      : permissions.some(permission => user.permissions[permission]);
  }
}

function toSnake(str) {
  return str
    .trim()
    .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
    .replace(/[-\s]+/g, '_')
    .toLowerCase();
}

const deprecatedPermissions = {
  'client_requests': 'tasks_client_requests',
  'engagements': 'tasks_engagements',
  'billing': 'one of the new billing permissions',
  'payments': 'one of the new payments permissions',
  'tasks' : 'one of the new tasks permissions',
  'client_request_templates': 'templates_client_requests',
  'irs_notices': 'tasks_notices',
  'admin': '',
  'team_member': '',
  'client': '',
  'practice_settings_team_member': 'company_settings_team_member',
  'practice_settings_company_profile': 'company_settings_company_info',
  'practice_settings_custom_branding': 'company_settings_custom_branding',
  'practice_settings_integrations': 'integrations_configure',
  'dates': '',
  'notifications': '',
  'practice_settings_access_levels': '',
}
