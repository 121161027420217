import { useState, useEffect } from "react";
import { hasAccess } from "./permission.helpers";
import UseWithUserAndTenant from "./use-with-user-and-tenant";

export function useHasAccess(permissions = [], requireAll) {
  if (!permissions || !permissions.length) {
    console.warn("No permissions were passed to `useHasAccess`")
  }
  const [user, tenant] = UseWithUserAndTenant()
  const [access, setAccess] = useState()

  useEffect(() => {
    if (user) {
      setAccess(hasAccess(user)(permissions, requireAll))
    }
  }, [user]);
  return access;
}
