import React from 'react';
import PropTypes from 'prop-types';
import styles from './practice-management-freemium-welcome-back-modal.styles.css';
import {CprButtonIcon} from 'canopy-styleguide!sofe';

PracticeManagementFreemiumWelcomeBackModal.propTypes = {
  contactsCount: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
}

export default function PracticeManagementFreemiumWelcomeBackModal(props) {
  return (
    <div className="cps-modal">
      <div className="cps-modal__screen"></div>
      <div className={`${styles.dialog} cps-modal__dialog cps-card__height-3`}>
        <div className={styles.header}>
          <CprButtonIcon
            icon="close-large"
            ariaText="Close"
            onClick={close}
          />
        </div>
        <div className={`${styles.body} cps-card__body cps-body`}>
          <img
            className={styles.icon}
            src="https://cdn.canopytax.com/static/empty-states/es_contacts.svg"
          />
          <div className="cps-subheader cps-wt-bold">
            Welcome Back!
          </div>
          <div className={`${styles.updatedText} cps-wt-semibold`}>
            We've updated a few things since you've been gone.
          </div>
          <div>
            <span>You can now enjoy Canopy Practice Management free using up to 20 of your contacts - don't worry, </span>
            {props.contactsCount ? (
               <span>all your contacts are still here.</span>
            ) : (
              <span>we've archived all your previous contacts.</span>
            )}
          </div>
          <div className={styles.upgradeText}>
            {props.contactsCount ? (
              <span>If you'd like to add more than 20 contacts, call </span>
            ) : (
              <span>Simply unarchive those 20 contacts you'd like to work with again or call </span>
            )}
            <span><span className="cps-wt-semibold">1-855-616-7305, ext. 1</span> and upgrade to a contact pricing tier.</span>
          </div>
        </div>
      </div>
    </div>
  )

  function close() {
    sessionStorage.setItem('hasClosedPracticeManagementFreemiumWelcomeBackModal', true);
    props.close();
  }
}