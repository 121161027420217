import {isPaidContactLimitModelTier} from './contact-limit-model.helper';

export function hasLicense(type, licenses, contactLimit, hasFreeTranscripts) {
  return isFreeLicense(type, contactLimit, hasFreeTranscripts)
    || hasPurchasedLicense(type, licenses, contactLimit, hasFreeTranscripts)
}

// Only use this method when absolutely necessary e.g. when using a permission does not make sense.
// Using permissions is the preferred way to restrict/allow features
export function userHasOnlyLicense(user, type) {
  if (!type || !user || user.licenses?.length !== 1) {
    return false;
  }
  return user.licenses?.[0] === type;
}

export function isFreeLicense(type, contactLimit, hasFreeTranscripts) {
  return isContactLimitPracticeManagementLicense(type, contactLimit)
    || isFreeTranscriptsLicense(type, hasFreeTranscripts)
    || isFreeTaxPrepLicense(type)
}

export function hasPurchasedLicense(type, licenses, contactLimit, hasFreeTranscripts) {
  if (isFreeLicense(type, contactLimit, hasFreeTranscripts)) {
    if (isContactLimitPracticeManagementLicense(type, contactLimit)) {
      return isPaidContactLimitModelTier(contactLimit);
    }

    return false;
  }

  return hasPurchasedTotal(type, licenses);
}

export function isWithinLicenseLimit(type, licenses, contactLimit, hasFreeTranscripts) {
  return getAvailableLicenseCount(type, licenses, contactLimit, hasFreeTranscripts) >= 0;
}

export function getAvailableLicenseCount(type, licenses, contactLimit, hasFreeTranscripts) {
  if (isFreeLicense(type, contactLimit, hasFreeTranscripts)) {
    return Infinity;
  } else if (licenses) {
    if (licenses[type] && (typeof licenses[type].totalPurchased === 'number') && (typeof licenses[type].totalAssigned === 'number')) {
      return licenses[type].totalPurchased - licenses[type].totalAssigned;
    } else {
      return 0;
    }
  }

  return -1;
}

// DEPRECATED ===============================================
// Use 'isContactLimitPracticeManagementLicense' instead
export function isFreemiumPracticeManagementLicense(type, contactLimit) {
  return type === 'practiceManagement' && !!contactLimit;
}
// ==========================================================

export function isContactLimitPracticeManagementLicense(type, contactLimit) {
  return (type === 'practiceManagement' || type === 'clientManagement') && !!contactLimit;
}

function hasPurchasedTotal(type, licenses) {
  return !!licenses && !!licenses[type] && !!licenses[type].totalPurchased;
}

function isFreeTranscriptsLicense(type, hasFreeTranscripts) {
  return type === 'transcripts' && hasFreeTranscripts;
}

function isFreeTaxPrepLicense(type) {
  return type === 'taxPrep';
}
