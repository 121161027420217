export function isContactLimitModel(contactLimit) {
  return !!contactLimit;
}

export function isFreeContactLimitModelTier(contactLimit) {
  return isContactLimitModel(contactLimit) && !window.tenant.is_active;
}

export function isPaidContactLimitModelTier(contactLimit) {
  return isContactLimitModel(contactLimit) && window.tenant.is_active;
}
