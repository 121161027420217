// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-legal-agreements-legal-agreements-modal-styles__header--9dJCi {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.src-legal-agreements-legal-agreements-modal-styles__body--fdQw- {\n  margin-top: 32px;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  min-height: 0;\n}\n\n.src-legal-agreements-legal-agreements-modal-styles__policyWrapper--2100O {\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.src-legal-agreements-legal-agreements-modal-styles__modal--3kfg1 {\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 100000;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  background-color: rgb(244, 245, 248);\n}\n\n.src-legal-agreements-legal-agreements-modal-styles__card--1ScX2 {\n  max-width: calc(100% - 16px);\n  width: 620px;\n  padding: 34px 24px;\n  top: 5%;\n  height: calc(100vh - 10%);\n  display: flex;\n  flex-direction: column;\n  background: rgb(255, 255, 255);\n  border-radius: 1px;\n  border: 1px solid rgb(233, 233, 233);\n  box-shadow: 0px 10px 30px -24px rgba(75, 78, 83, 0.4);\n}\n\n.src-legal-agreements-legal-agreements-modal-styles__footer--2cwLH {\n  margin-top: 24px;\n  margin-bottom: 8px;\n}\n\n.src-legal-agreements-legal-agreements-modal-styles__buttons--1QBrp {\n  display: flex;\n}\n\n.src-legal-agreements-legal-agreements-modal-styles__agreementTerm--3RY9u {\n  border: 1px solid var(--cps-color-gray4);\n  border-radius: 5px;\n  overflow-y: auto;\n  flex: 1;\n  padding: 0px 16px;\n  min-height: 0;\n}\n\n.src-legal-agreements-legal-agreements-modal-styles__agreementTerm--3RY9u h2 {\n  font-weight: bold;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "src-legal-agreements-legal-agreements-modal-styles__header--9dJCi",
	"body": "src-legal-agreements-legal-agreements-modal-styles__body--fdQw-",
	"policyWrapper": "src-legal-agreements-legal-agreements-modal-styles__policyWrapper--2100O",
	"modal": "src-legal-agreements-legal-agreements-modal-styles__modal--3kfg1",
	"card": "src-legal-agreements-legal-agreements-modal-styles__card--1ScX2",
	"footer": "src-legal-agreements-legal-agreements-modal-styles__footer--2cwLH",
	"buttons": "src-legal-agreements-legal-agreements-modal-styles__buttons--1QBrp",
	"agreementTerm": "src-legal-agreements-legal-agreements-modal-styles__agreementTerm--3RY9u"
};
module.exports = exports;
